/*
 * ====================================================================
 * Grow Template
 * @package  Grow Template
 * @author   GrowGroup.Inc <info@grow-group.jp>
 * @license  MIT Licence
 * ====================================================================
 */

(function ($) {
  var growApp = function () {

  };

  /************************
   * please insert your code
   *************************/
  growApp.prototype.myCode = function () {
  }

  growApp.prototype.addAnimation = function () {

    var loaderFunction = function () {
      var $image = $(".c-main-visual__image")

      if ($image) {
        $image.addClass("is-animated");
      }
    }
    loaderFunction();
  }

  growApp.prototype.scrollShow = function () {
    $(window).scroll(function (){
      $(".c-block-environment__images").each(function(){
        var hit		= $(this).offset().top;
        var scroll	= $(window).scrollTop();
        var wHeight	= $(window).height();

        if (scroll > hit - wHeight + wHeight/100){
          $(this).addClass("is-animated");
        }
      });
    });
  }

  $(function () {
    var app = new growApp();
    app.myCode();
    app.addAnimation();
    app.scrollShow();
  });
})(jQuery);
